import React, {Fragment, useState} from 'react'
import { useDispatch, useSelector } from "react-redux"

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Grid,
    Button
} from "@mui/material"
import Alert from '@mui/material/Alert';
import { DataGrid } from '@mui/x-data-grid';
import Draggable from 'react-draggable';
import Paper from '@mui/material/Paper';
import LinkIcon from '@mui/icons-material/Link';

// context
import { PeopleState, clearError,searchCWPatient, linkCWPatient} from './peopleSlice'

//import local component
import Spinner from '../../layouts/Spinner'


function PaperComponent(props) {
    return (
      <Draggable
        handle="#form-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} />
      </Draggable>
    );
  }

const CommonWellLinking = ({person}) => {

    const { error, cwLinks } = useSelector(PeopleState)
    const dispatch = useDispatch()

    const {id, isCwEnrolled} = person

    const initialState = {
        open: false,
    } 

    const [dialog, setDialog] = useState(initialState)
    const { open } = dialog

    const handleClickOpen = () => { 
        dispatch(clearError())
        dispatch(searchCWPatient(id))
        setDialog({...dialog, open: true}) }
    const handleClose = (event, reason) => {
        dispatch(clearError())
        setDialog(initialState)
    }
    const handleSubmit = () => {
        dispatch(linkCWPatient(id,selectionModel))
        setDialog(initialState)
    }

    const columns = [
        {field: 'name', headerName: 'Name', width: 200, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.name) {
                    params.row.name.forEach((point) => {
                        result.push(`${point.family}, ${point.given.join(' ')}`);
                })
                } else {
                    result = [""];
                }
                return result.join(", ");
            }},
        {field: 'address', headerName: 'Address', width: 400, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.address) {
                    params.row.address.forEach((point) => {
                        result.push(`${point.line?.join(' ')}, ${point.city}, ${point.state}, ${point.postalCode}`);
                })
                } else {
                    result = [""];
                }
                return result.join(", ");
            }
        },
        { field: 'birthDate', headerName: 'Birth Date', width: 150,
            renderCell: (params) => {
                return params.row.birthDate
            }},
        { field: 'gender', headerName: 'Gender', width: 150,
            renderCell: (params) => {
                return params.row.gender
            }}
    ]

    const [selectionModel, setSelectionModel] = useState([])

        if(!isCwEnrolled) return (
            <Fragment>
                <Box display='flex' justifyContent='flex-mid'>
                    <Button variant="contained" startIcon={<LinkIcon/>} size={'small'}sx={{marginRight: 1}} onClick={() => {handleClickOpen()}}>Link Patient</Button>
                </Box>

                <Dialog open={open} id="submitRequestModal" onClose={handleClose} aria-labelledby={'form-dialog-title'} fullWidth={true} maxWidth={'md'} PaperComponent={PaperComponent}>
                <DialogTitle id='form-dialog-title'>Link Patient</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        { error && <Alert severity={'error'}>{error}</Alert> }
                    </DialogContentText>

                    <Grid container spacing={2}>
                        {cwLinks?.length === 0 && <Spinner />}
                        {cwLinks?.length !== 0 &&
                            <Grid item xs={12}>
                                <div style={{ height: 200, width: '100%' }}>
                                    <DataGrid
                                    style={{
                                        backgroundColor: 'white'
                                    }}
                                    sx={{
                                        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                        display: "none"
                                        }
                                    }}
                                    getRowId={(row) => row.linkId}
                                    rows={cwLinks}
                                    columns={columns}
                                    checkboxSelection 
                                    onSelectionModelChange={(newSelectionModel) => {
                                        setSelectionModel(newSelectionModel)   
                                    }}
                                    selectionModel={selectionModel}
                
                                    />
                                </div>
                            </Grid>  
                        }  
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={'primary'}>
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color={'primary'}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            </Fragment>
        )
        else return <div></div>
}

export default CommonWellLinking